
import { defineComponent, toRef, ref, reactive, onMounted, toRefs } from "vue";
import SatSearchInput from "@/components/SatSearchInput.vue";
import SatCarousel from "@/components/carousel/SatCarousel.vue";
import SatIcon from "@/components/SatIcon.vue";
import CompetitionCard from "@/components/carousel/CompetitionCard.vue";
import useCompetitionRepositories from "@/_composables/useCompetitionRepositories";
import {
  BasePagination,
  CompetitionQueryDto,
  CompetitionType,
  ICompetition,
  QueryParams,
} from "@/_modules/types";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { Helpers } from "@/_modules/helpers";

export default defineComponent({
  components: {
    SatSearchInput,
    SatCarousel,
    CompetitionCard,
    LoadingOutlined,
    SatIcon,
  },
  setup() {
    const helpers = Helpers;

    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const limit = 5;
    const visibleMoreSection = ref(false);
    const loading = ref(false);
    const searching = ref(false);
    const state = reactive({
      currentPageInternation: 1,
      currentPageZone: 1,
      currentPageNationals: 1,
      currentPageRegionals: 1,
      currentPageContinentals: 1,
      currentPageGlobals: 1,
      currentPageDistrict: 1,

      totalPageInternation: 1,
      totalPageZone: 1,
      totalPageNationals: 1,
      totalPageRegionals: 1,
      totalPageContinentals: 1,
      totalPageGlobals: 1,
      totalPageDistrict: 1,
      query: {
        search: {} as CompetitionQueryDto,
        districts: {
          page: 1,
          size: helpers.checkWindowSlide(),
        },
        zones: {
          page: 1,
          size: helpers.checkWindowSlide(),
        },
        nationals: {
          page: 1,
          size: helpers.checkWindowSlide(),
        },
        regionals: {
          page: 1,
          size: helpers.checkWindowSlide(),
        },
        continentals: {
          page: 1,
          size: helpers.checkWindowSlide(),
        },
        internationals: {
          page: 1,
          size: helpers.checkWindowSlide(),
        },
        globals: {
          page: 1,
          size: helpers.checkWindowSlide(),
        },
      },
      totalPages: {
        districts: 0,
        zones: 0,
        nationals: 0,
        regionals: 0,
        continentals: 0,
        internationals: 0,
        globals: 0,
      },
      filtered: 0,
      districts: [] as ICompetition[],
      zones: [] as ICompetition[],
      nationals: [] as ICompetition[],
      regionals: [] as ICompetition[],
      continentals: [] as ICompetition[],
      internationals: [] as ICompetition[],
      globals: [] as ICompetition[],
      listCompetition: [],
      countList: 0,
    });

    // get competition by level
    const { getCompetitions } = useCompetitionRepositories();

    // get all competition in search
    const { repositories: competitions } = useCompetitionRepositories(
      toRef(state.query, "search")
    );

    onMounted(async () => {
      // smell code , should be written in switch case + for each
      loading.value = true;
      await fetchCompetitions(CompetitionType.DISTRICT, {
        ...state.query.districts,
      });

      await fetchCompetitions(CompetitionType.ZONE, {
        ...state.query.zones,
      });

      await fetchCompetitions(CompetitionType.NATIONAL, {
        ...state.query.nationals,
      });

      if (state.countList < 3) {
        await fetchCompetitions(CompetitionType.REGIONAL, {
          ...state.query.regionals,
        });
      }

      if (state.countList < 3) {
        await fetchCompetitions(CompetitionType.CONTINENTAL, {
          ...state.query.continentals,
        });
      }

      if (state.countList < 3) {
        await fetchCompetitions(CompetitionType.INTERNATIONAL, {
          ...state.query.internationals,
        });
      }
      if (state.countList < 3) {
        await fetchCompetitions(CompetitionType.GLOBAL, {
          ...state.query.globals,
        });
      }
      loading.value = false;
    });

    const fetchCompetitions = async (type: CompetitionType, q: QueryParams) => {
      const { competition } =
        ((await getCompetitions(type, q)) as BasePagination<ICompetition[]>) ??
        {};
      if (!competition) {
        return {
          type: type,
          count: state.countList,
        };
      }

      const { data, lastPage, currentPage } = competition;
      switch (type) {
        case CompetitionType.DISTRICT:
          state.districts = data;
          state.totalPageDistrict = lastPage;
          state.countList++;
          state.currentPageDistrict = currentPage;
          break;
        case CompetitionType.ZONE:
          state.zones = data;
          state.totalPageZone = lastPage;
          state.countList++;
          state.currentPageZone = currentPage;
          break;
        case CompetitionType.NATIONAL:
          state.nationals = data;
          state.totalPageNationals = lastPage;
          state.countList++;
          state.currentPageNationals = currentPage;

          break;
        case CompetitionType.REGIONAL:
          state.regionals = data;
          state.totalPageRegionals = lastPage;
          state.countList++;
          state.currentPageRegionals = currentPage;

          break;
        case CompetitionType.CONTINENTAL:
          state.continentals = data;
          state.totalPageContinentals = lastPage;
          state.countList++;
          state.currentPageContinentals = currentPage;
          break;
        case CompetitionType.INTERNATIONAL:
          state.internationals = data;
          state.totalPageInternation = lastPage;
          state.countList++;
          state.currentPageInternation = competition.currentPage;

          break;
        case CompetitionType.GLOBAL:
          state.globals = data;
          state.totalPageGlobals = lastPage;
          state.countList++;
          state.currentPageGlobals = currentPage;
          break;
      }
      return {
        count: state.countList,
      };
    };
    const loadMore = async () => {
      loading.value = true;
      await fetchCompetitions(CompetitionType.REGIONAL, {
        ...state.query.regionals,
      });
      await fetchCompetitions(CompetitionType.CONTINENTAL, {
        ...state.query.continentals,
      });
      await fetchCompetitions(CompetitionType.INTERNATIONAL, {
        ...state.query.internationals,
      });
      await fetchCompetitions(CompetitionType.GLOBAL, {
        ...state.query.globals,
      });
      visibleMoreSection.value = true;
      loading.value = false;
    };
    const onSearch = (val: { name: string }) => {
      searching.value = !!val;
      if (val) {
        if (val.name) {
          state.query.search = {
            ...val,
            page: 1,
            size: limit,
          };
        } else {
          searching.value = false;
        }
      } else {
        state.query.search = {};
      }
    };
    const onNextVideoDISTRICT = async () => {
      state.currentPageDistrict = state.currentPageDistrict + 1;
      await fetchCompetitions(CompetitionType.DISTRICT, {
        page: state.currentPageDistrict,
        size: helpers.checkWindowSlide(),
      });
    };

    const onPreviousVideoDISTRICT = async () => {
      state.currentPageDistrict = state.currentPageDistrict - 1;
      await fetchCompetitions(CompetitionType.DISTRICT, {
        page: state.currentPageDistrict,
        size: helpers.checkWindowSlide(),
      });
    };

    const onNextVideoZone = async () => {
      state.currentPageZone = state.currentPageZone + 1;
      await fetchCompetitions(CompetitionType.ZONE, {
        page: state.currentPageZone,
        size: helpers.checkWindowSlide(),
      });
    };

    const onPreviousVideoZone = async () => {
      state.currentPageZone = state.currentPageZone - 1;

      await fetchCompetitions(CompetitionType.ZONE, {
        page: state.currentPageZone,
        size: helpers.checkWindowSlide(),
      });
    };

    const onNextVideoNational = async () => {
      state.currentPageNationals = state.currentPageNationals - 1;

      await fetchCompetitions(CompetitionType.NATIONAL, {
        page: state.currentPageNationals,
        size: helpers.checkWindowSlide(),
      });
    };

    const onPreviousVideoNational = async () => {
      state.currentPageNationals = state.currentPageNationals - 1;

      await fetchCompetitions(CompetitionType.NATIONAL, {
        page: state.currentPageNationals,
        size: helpers.checkWindowSlide(),
      });
    };

    const onNextVideoRegionals = async () => {
      state.currentPageRegionals = state.currentPageRegionals + 1;
      await fetchCompetitions(CompetitionType.REGIONAL, {
        page: state.currentPageRegionals,
        size: helpers.checkWindowSlide(),
      });
    };

    const onPreviousVideoRegionals = async () => {
      state.currentPageRegionals = state.currentPageRegionals - 1;

      await fetchCompetitions(CompetitionType.REGIONAL, {
        page: state.currentPageRegionals,
        size: helpers.checkWindowSlide(),
      });
    };

    const onNextVideoContinentals = async () => {
      state.currentPageContinentals = state.currentPageContinentals + 1;

      await fetchCompetitions(CompetitionType.CONTINENTAL, {
        page: state.currentPageContinentals,
        size: helpers.checkWindowSlide(),
      });
    };

    const onPreviousVideoContinentals = async () => {
      state.currentPageContinentals = state.currentPageContinentals - 1;

      await fetchCompetitions(CompetitionType.CONTINENTAL, {
        page: state.currentPageContinentals,
        size: helpers.checkWindowSlide(),
      });
    };

    const onNextVideoInternationals = async () => {
      state.currentPageInternation = state.currentPageInternation + 1;
      await fetchCompetitions(CompetitionType.INTERNATIONAL, {
        page: state.currentPageInternation,
        size: helpers.checkWindowSlide(),
      });
    };

    const onPreviousVideoInternationals = async () => {
      state.currentPageInternation = state.currentPageInternation - 1;

      await fetchCompetitions(CompetitionType.INTERNATIONAL, {
        page: state.currentPageInternation,
        size: helpers.checkWindowSlide(),
      });
    };

    const onNextVideoGlobal = async () => {
      state.currentPageGlobals = state.currentPageGlobals + 1;

      await fetchCompetitions(CompetitionType.GLOBAL, {
        page: state.currentPageGlobals,
        size: helpers.checkWindowSlide(),
      });
    };

    const onPreviousVideoGlobal = async () => {
      state.currentPageGlobals = state.currentPageGlobals - 1;

      await fetchCompetitions(CompetitionType.GLOBAL, {
        page: state.currentPageGlobals,
        size: helpers.checkWindowSlide(),
      });
    };

    return {
      t,
      store,
      ...toRefs(state),
      visibleMoreSection,
      loadMore,
      loading,
      searching,
      onSearch,
      helpers,
      competitions,
      onNextVideoDISTRICT,
      onPreviousVideoDISTRICT,
      onNextVideoZone,
      onPreviousVideoZone,
      onNextVideoNational,
      onPreviousVideoNational,
      onNextVideoRegionals,
      onPreviousVideoRegionals,
      onNextVideoContinentals,
      onPreviousVideoContinentals,
      onNextVideoInternationals,
      onPreviousVideoInternationals,
      onNextVideoGlobal,
      onPreviousVideoGlobal,
    };
  },
});
